import React from 'react';
import styled from 'styled-components';
import { RecommendationsSection } from 'views/home/recommendations-section';
import { Offer } from 'views/offer';
import { Content } from 'views/offer/components/Content';
import { Header } from 'views/offer/components/Header';
import { Image } from 'views/offer/components/Image';
import { ImageWrapper } from 'views/offer/components/ImageWrapper';
import { List } from 'views/offer/components/List';
import { ListItem } from 'views/offer/components/ListItem';
import { Paragraph } from 'views/offer/components/Paragraph';
import { Section } from 'views/offer/components/Section';
import { Subtitle } from 'views/offer/components/Subtitle';
import { getOfferBreadcrumbsLinks } from 'views/offer/helpers';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import { graphql, useStaticQuery } from 'gatsby';
import OtherTreatmentsJson from 'jsons/otherTreatment.json';
import { Layout } from 'layouts';

const SImageWrapper = styled(ImageWrapper)`
  max-width: 1920px;
`;

const Offer6 = ({ location }) => {
  const { pathname } = location;
  const breadcrumbsLinks = getOfferBreadcrumbsLinks(pathname);

  const images = useStaticQuery(graphql`
    query {
      one: file(relativePath: { eq: "ig-lzy-1.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 550, quality: 100, formats: [WEBP])
        }
      }
      two: file(
        relativePath: {
          eq: "img-13_large&ideale medycyna estetyczna warszawa wola wypelnienie doliny lez.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 550, quality: 100, formats: [WEBP])
        }
      }
      fourth: file(relativePath: { eq: "loyalty.png" }) {
        childImageSharp {
          gatsbyImageData(width: 750, quality: 100, formats: [WEBP])
        }
      }
    }
  `);
  return (
    <Layout>
      <Offer otherTreatments={OtherTreatmentsJson.wypelnienieDolinyLez}>
        <Header breadcrumbsLinks={breadcrumbsLinks}>
          Wypełnianie doliny łez kwasem hialuronowym
        </Header>
        <Content>
          <SImageWrapper>
            <Image
              src={images.fourth.childImageSharp}
              alt="wypełnienie kwasem hialuronowym doliny łez ideale medycyna estetyczna warszawa wola"
            />
          </SImageWrapper>
          <Section>
            <Subtitle>Czym jest dolina łez?</Subtitle>
            <Paragraph>
              Dolina łez to termin używany w medycynie estetycznej do opisania
              naturalnego zagłębienia, które przebiega poniżej dolnej powieki,
              rozciągając się od wewnętrznego kącika oka w kierunku policzków.
              Ta specyficzna cecha twarzy jest anatomiczna , jednak z wiekiem
              lub z powodu predyspozycji genetycznych może stać się bardziej
              widoczna, co często jest postrzegane jako oznaka zmęczenia lub
              starzenia się skóry .
            </Paragraph>
            <Paragraph>
              Z wiekiem skóra traci elastyczność i objętość, co może sprawić, że
              dolina łez staje się głębsza i bardziej zaznaczona. Ponadto,
              ubytek tkanki tłuszczowej w okolicach oczu oraz zmiany w
              strukturze kostnej mogą przyczyniać się do bardziej wyraźnego
              podkreślenia tej doliny, co może skutkować "cieniem" nadając
              twarzy zmęczony wygląd i powstaje defekt estetyczny.
            </Paragraph>
            <Paragraph>
              <b>Wypełnienie doliny łez kwasem hialuronowym</b> jest popularną
              metodą leczenia, która ma na celu zminimalizowanie widoczności
              tego zagłębienia poprzez przywrócenie utraconej objętości i
              poprawę konturu okolicy oczodołowej. Kwas hialuronowy jest
              substancją naturalnie występującą w organizmie ludzkim, która
              posiada zdolność wiązania cząsteczek wody, dzięki czemu skutecznie
              nawilża skórę i przywraca jej jędrność.
            </Paragraph>
            <Subtitle>Wskazania do zabiegu redukującego dolinę łez</Subtitle>
            <Paragraph>
              Wskazaniem do wykonania zabiegu wypełnienia doliny łez kwasem
              hialuronowym jest wiotczenie skóry wokół oczu, okolicy powiek
              dolnych, utrata podskórnej tkanki tłuszczowej tej okolicy oraz
              obecność zagłębień skóry pod oczami w obrębie oczodołów,
              przechodzących skośnie na policzki i tworzących dolinę łez.
            </Paragraph>
          </Section>
          <Section>
            <Subtitle>Opis zabiegu wypełnienia doliny łez</Subtitle>
            <Paragraph>
              <b>Zabieg wypełniania doliny łez</b>kwasem hialuronowym zawsze
              poprzedzony jest indywidualną konsultacją lekarską. Polega on na
              podaniu kwasu hialuronowego, za pomocą kaniuli w odpowiednie
              punkty w zagłębieniu okolicy pod oczami. Zmniejszenie zagłębienia
              oraz widoczności prześwitujących przez skórę naczyń krwionośnych w
              tej okolicy daje efekt wypoczętych oczu. Zabieg wykonuje się w
              miejscu wcześniej znieczulonym przy pomocy specjalnego kremu.
            </Paragraph>
          </Section>
          <Section>
            <Subtitle>
              Po jakim czasie i jak długo widoczne są efekty pod oczami?
            </Subtitle>
            <Paragraph>
              Efekty zabiegu <b>wypełnienia doliny łez kwasem hialuronowym</b>
              widoczne są natychmiast. W zależności od preparatu efekty mogą się
              utrzymywać od 6 do 12 miesięcy, następnie preparat zostaje
              naturalnie zmetabolizowany przez organizm. Po tym czasie zabieg
              należy powtórzyć. Zdjęcia ukazują efekt zabiegu u konkretnego
              pacjenta. Efekt zabiegu może się różnić w zależności od
              indywidualnych cech pacjenta, liczby powtórzeń zabiegu, stosowania
              się pacjenta do zaleceń pozabiegowych oraz umiejętności i
              doświadczenia osoby przeprowadzającej zabieg.
            </Paragraph>
            <Subtitle>
              Zdjęcia przed po zabiegu wypełniania doliny łez kwasem
              hialuronowym
            </Subtitle>
            <ImageWrapper>
              <Image
                src={images.one.childImageSharp}
                alt="wypełnienie doliny łez kwas hialuronowy przed po ideale medycyna estetyczna warszawa wola"
              />
              <Image
                src={images.two.childImageSharp}
                alt="dolina łez kwas hialuronowy przed po ideale medycyna estetyczna warszawa wola"
              />
            </ImageWrapper>
          </Section>
          <Section>
            <Subtitle>Cena zebiegu wypełnienia doliny łez</Subtitle>
            <Paragraph>
              Cena zabiegu to 1 250 zł. Przy pierwszej wizycie odbierz swoją
              kartę lojalnościową i otrzymaj{' '}
              <b>-30% na czwarty zabieg medycyny estetycznej</b> wykonany w
              Klinice IDEALE.
            </Paragraph>
          </Section>
          <RecommendationsSection />
          <Section>
            <Subtitle>
              Przeciwwskazania do zebiegu wypełnienia doliny łez
            </Subtitle>
            <List>
              <ListItem>Skłonność do obrzęków okolic oczu</ListItem>
              <ListItem>Niewyrównane hormonalnie choroby tarczycy</ListItem>
              <ListItem>Okres ciąży, połogu i karmienia piersią</ListItem>
              <ListItem>Skłonność do powstawania blizn przerostowych</ListItem>
              <ListItem>Nadwrażliwość na kwas hialuronowy</ListItem>
              <ListItem>Choroby nowotworowe</ListItem>
              <ListItem>Zaburzenia krzepnięcia</ListItem>
              <ListItem>Miejscowy stan zapalny skóry</ListItem>
            </List>
          </Section>
          <Section>
            <Subtitle>Czym jest kwas hialuronowy?</Subtitle>
            <Paragraph>
              Kwas hialuronowy to związek chemiczny z grupy glikozaminoglikanów,
              który występuje naturalnie w organizmach żywych, w tym w ludzkim
              ciele. Jest to substancja o żelowej konsystencji, która pełni
              ważną rolę w utrzymaniu odpowiedniego nawilżenia i elastyczności
              skóry, a także w procesach regeneracji tkanek.
            </Paragraph>
            <Paragraph>
              Kwas hialuronowy jest składnikiem istotnym dla zdrowej skóry,
              stawów i oczu. W skórze występuje głównie w warstwie skóry
              właściwej, gdzie jest odpowiedzialny za utrzymanie odpowiedniego
              poziomu nawilżenia. Posiada zdolność wiązania i zatrzymywania
              dużej ilości wody, co przyczynia się do utrzymania jędrność skóry
              , elastyczności i gładkości skóry.
            </Paragraph>
            <Paragraph>
              Wraz z upływem czasu, produkcja naturalnego kwasu hialuronowego w
              organizmie zmniejsza się, co może prowadzić do utraty nawilżenia i
              powstawania zmarszczek. W związku z tym, kwas hialuronowy jest
              szeroko wykorzystywany w medycynie estetycznej. Może być stosowany
              w postaci zastrzyków lub jako składnik kosmetyków, takich jak
              kremy, serum czy maski, w celu nawilżenia skóry, redukcji
              zmarszczek i poprawy ogólnego wyglądu skóry.
            </Paragraph>
            <Paragraph>
              Ponadto, kwas hialuronowy może być stosowany w medycynie, na
              przykład w leczeniu chorób stawów, takich jak osteoartroza, gdzie
              wstrzykiwany jest bezpośrednio do stawu w celu zmniejszenia bólu i
              poprawy funkcji stawu.
            </Paragraph>
            <Subtitle>Dlaczego IDEALE?</Subtitle>
            <Paragraph>
              Zapraszamy do <b>IDEALE Medycyna Estetyczna, Wola, Warszawa</b>,
              gdzie odzyskasz swoją pewność siebie w przyjemnej atmosferze. Nasz
              doświadczony lekarz medycyny estetycznej indywidualnie dopasuje
              odpowiednie zabiegi dla Ciebie, dzięki którym wyjdziesz od nas
              piękna, naturalna i promienna. Nasza klinika mieści się w samym
              sercu Woli, zapewniając łatwy dostęp dla naszych klientów.
            </Paragraph>
            <Paragraph>
              W IDEALE skupiamy się na dostarczaniu najskuteczniejszych zabiegów
              medycyny estetycznej, które czerpiemy prosto z{' '}
              <b>międzynarodowych kongresów i szkoleń</b>. Dążymy do zapewnienia
              najwyższej jakości usług, a nasz zespół stale poszerza swoją
              wiedzę i umiejętności, aby sprostać Twoim oczekiwaniom.
            </Paragraph>
            <Paragraph>
              Razem z nami odkryjesz potencjał swojej urody i poczujesz się w
              pełni zadbana i zrelaksowana. Zaufaj naszemu doświadczeniu i
              profesjonalizmowi, abyś mógł cieszyć się pięknem, jakie tkwi w
              Tobie.
            </Paragraph>
            <Paragraph>
              IDEALE, Gabinet Medycyny Estetycznej, ul. Sokołowska 9, Wola,
              Warszawa.
            </Paragraph>
          </Section>
        </Content>
      </Offer>
    </Layout>
  );
};

export default Offer6;

export const Head = () => <SEO tags={TAGS.wypelnianieDolinyLez} />;
